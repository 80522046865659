import { bindable, inject }  from 'aurelia-framework';
import { AppContainer }      from 'resources/services/app-container';
import { BaseFormViewModel } from 'base-form-view-model';
import { ClientsRepository } from 'modules/wemake/clients/services/repository';
import { FormSchema }        from 'modules/wemake/clients/reports/daily/form-schema';
import { DialogController }  from 'aurelia-dialog';

@inject(AppContainer, ClientsRepository, FormSchema, DialogController)
export class ClientDailyReport extends BaseFormViewModel {

    @bindable settings = {
        title:   'form.title.send-daily-report',
        buttons: false,
    };

    discardable = true;

    formId = 'wemake-clients-daily-report';

    alert = {};
    model = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     * @param dialogController
     */
    constructor(appContainer, repository, formSchema, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.formSchema       = formSchema;
        this.dialogController = dialogController;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'wemake.clients.manage',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(client) {
        this.client = client;

        this.settings.subtitle = client.name;

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();
        this.schema       = this.formSchema.schema();
    }

    /**
     * Submits form
     */
    submit() {
        this.alert = null;

        // calls repository create method
        return this.repository.sendDailyReport(this.client.id, this.model)
            .then((response) => {
                // creates a new alert message
                this.alert = this.alertMessage(response.status, response.message, response.errors);

                if (response.status === true) {
                    this.appContainer.notifier.successNotice(response.message);

                    this.dialogController.ok();
                }
            })
            .catch((error) => console.log(error));
    }

}
