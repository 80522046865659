import { inject }                                       from 'aurelia-framework';
import { ServiceType }                                  from 'modules/wemake/models/service-type';
import { ClientSubscription }                           from 'modules/wemake/models/client-subscription';
import { Country }                                      from 'modules/administration/models/country';
import { ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import { DialogService }                                from 'aurelia-dialog';

@inject(ValidationControllerFactory, DialogService)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        country_id: Country.PORTUGAL,
    };

    /**
     * Constructor
     *
     * @param validationControllerFactory
     * @param dialogService
     */
    constructor(validationControllerFactory, dialogService) {
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.dialogService        = dialogService;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ClientSubscription}
     */
    model() {
        let model = new ClientSubscription();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.country_id = {
            type:         'select2',
            key:          'country_id',
            id:           'client_service_country_id',
            label:        'form.field.country',
            size:         4,
            remoteSource: viewModel.repository.activeCountries.bind(viewModel.repository),
            observers:    [
                (newValue, oldValue) => {
                    this.diploma_classifications.instance.fetchData().then((options) => {
                        this.diploma_classifications.instance.enable(options.length > 0);
                        this.diploma_classifications.inputGroup.button.attributes.disabled = options.length === 0;
                    });
                    this.sources.instance.fetchData().then((options) => this.sources.instance.enable(options.length > 0));
                },
            ],
        };

        this.diploma_classifications = {
            type:                   'multiselect-native',
            key:                    'diploma_classifications',
            id:                     'client_service_diploma_classifications',
            label:                  'form.field.classifications',
            size:                   4,
            remoteSource:           (countryId) => viewModel.repository.diplomaClassificationsRepository.activeByCountry(countryId).then((diplomaClassifications) => {
                return diplomaClassifications.filter((diplomaClassification) => diplomaClassification.depth === 0);
            }),
            remoteSourceParameters: () => viewModel.model.country_id,
            processResults:         (item) => {
                item.name = item.order + ' - ' + item.ancestors_and_self.join(' » ');

                return item;
            },
            attributes:             {
                disabled: !isNumeric(viewModel.model.country_id),
            },
        };

        this.sources = {
            type:                   'multiselect-native',
            key:                    'sources',
            id:                     'client_service_sources',
            label:                  'form.field.source',
            size:                   4,
            remoteSource:           viewModel.repository.sourcesRepository.activeByCountry.bind(viewModel.repository.sourcesRepository),
            remoteSourceParameters: () => viewModel.model.country_id,
            attributes:             {
                disabled: !isNumeric(viewModel.model.country_id),
            },
        };

        this.service_type_id = {
            type:         'select2',
            key:          'service_type_id',
            id:           'client_service_service_type_id',
            label:        'form.field.service',
            size:         4,
            remoteSource: viewModel.repository.activeServiceTypes.bind(viewModel.repository),
            observers:    [
                (newValue, oldValue) => {
                    viewModel.model.consultant_id = null;

                    this.consultant_id.attributes.disabled = newValue === ServiceType.LEVEL_1;
                },
            ],
        };

        this.consultant_id = {
            type:         'select2',
            key:          'consultant_id',
            id:           'client_service_consultant_id',
            label:        'form.field.consultant',
            size:         4,
            remoteSource: viewModel.repository.activeConsultants.bind(viewModel.repository),
            attributes:   {
                disabled: true,
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.add',
            action:     () => {
                this.reset();

                this.validate(viewModel.model).then((result) => {
                    if (result.valid) {
                        viewModel.submit();
                    }
                });
            },
            attributes: {
                class: 'btn bg-teal float-right',
            },
            icon:       {
                attributes: {
                    class: 'icon-plus22',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.submitButton,
            ],
        };

        return [
            [this.country_id, this.diploma_classifications, this.sources],
            [this.service_type_id, this.consultant_id],
            [this.buttons],
        ];
    }

    /**
     * Resets validation controller.
     */
    reset() {
        return this.validationController.reset();
    }

    /**
     * Performs validation.
     *
     * @return {{}}
     */
    validate(model) {
        return this.validationController.validate({
            object: model,
            rules:  this.validationRules(),
        });
    }

    /**
     * Returns validation rules
     * @returns {{}}
     */
    validationRules() {
        return ValidationRules
            .ensure(this.country_id.key)
            .displayName(this.country_id.label)
            .required()

            .ensure(this.diploma_classifications.key)
            .displayName(this.diploma_classifications.label)
            .satisfies(value => Array.isArray(value) && value.length > 0)
            .withMessageKey('required')

            .ensure(this.sources.key)
            .displayName(this.sources.label)
            .satisfies(value => Array.isArray(value) && value.length > 0)
            .withMessageKey('required')

            .ensure(this.service_type_id.key)
            .displayName(this.service_type_id.label)
            .required()

            .ensure(this.consultant_id.key)
            .displayName(this.consultant_id.label)
            .required()
            .when(obj => (parseInt(obj.service_type_id, 10) === 2))
            .rules;
    }
}
