import { bindable, inject }      from 'aurelia-framework';
import { AppContainer }          from 'resources/services/app-container';
import { BaseFormViewModel }     from 'base-form-view-model';
import { ConsultantsRepository } from 'modules/wemake/consultants/services/repository';
import { DialogController }      from 'aurelia-dialog';

@inject(AppContainer, ConsultantsRepository, DialogController)
export class ConsultantClients extends BaseFormViewModel {

    @bindable settings = {
        title:   'form.title.clients',
        buttons: false,
    };

    clients = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     * @param repository
     */
    constructor(appContainer, repository, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.dialogController = dialogController;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'wemake.consultants.manage',
            'wemake.consultants.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(consultant) {
        return this.repository.clients(consultant.id).then((response) => this.clients = response);
    }

}
