import { inject }                   from 'aurelia-framework';
import { Client }                   from 'modules/wemake/models/client';
import { ClientStatus }             from 'modules/wemake/models/client-status';
import { ClientTypesRepository }    from 'modules/wemake/client-types/services/repository';
import { ClientStatusesRepository } from 'modules/wemake/client-statuses/services/repository';

@inject(ClientTypesRepository, ClientStatusesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id: ClientStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param clientTypesRepository
     * @param clientStatusesRepository
     */
    constructor(clientTypesRepository, clientStatusesRepository) {
        this.clientTypesRepository    = clientTypesRepository;
        this.clientStatusesRepository = clientStatusesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Client}
     */
    model() {
        let model = new Client();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns client data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    clientDataSchema(viewModel) {

        this.type_id = {
            type:         'select2',
            key:          'type_id',
            label:        'form.field.type',
            size:         4,
            remoteSource: this.clientTypesRepository.active.bind(this.clientTypesRepository),
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        this.number = {
            type:       'text',
            key:        'number',
            label:      'form.field.number',
            size:       2,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.designation',
            size:       6,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.subdomain = {
            type:     'text',
            key:      'subdomain',
            label:    'form.field.subdomain',
            required: false,
            size:     4,
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         4,
            remoteSource: this.clientStatusesRepository.active.bind(this.clientStatusesRepository),
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        return [
            [this.type_id],
            [this.number, this.name, this.subdomain],
            [this.status_id],
        ];
    }

    /**
     * Returns global buttons schema
     *
     * @returns {*[]}
     */
    globalButtonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('wemake.clients.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }
}
