import { BaseModel } from 'modules/core/models/base-model';

export class Client extends BaseModel {

    type_id       = null;
    number        = null;
    name          = null;
    status_id     = null;
    subscriptions = [];

}
