import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class MailSettingsRepository {

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Returns mail settings info
     *
     * @returns {*}
     */
    settings() {
        return this.httpClient.get('wemake/mail-settings/settings');
    }

    /**
     * Updates the mail settings
     *
     * @param data
     *
     * @returns {*}
     */
    update(data) {
        return this.httpClient.put('wemake/mail-settings', data);
    }

    /**
     * Updates the mail settings
     *
     * @param data
     *
     * @returns {*}
     */
    test(data) {
        return this.httpClient.post('wemake/mail-settings/test', data);
    }

    /**
     * Fetches mail drivers from server
     *
     * @returns {*}
     */
    drivers() {
        return this.httpClient.get('wemake/mail-settings/drivers');
    }

    /**
     * Fetches mail encryption types from server
     *
     * @returns {*}
     */
    encryptionTypes() {
        return this.httpClient.get('wemake/mail-settings/encryption-types');
    }

}
