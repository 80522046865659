import { bindable, bindingMode, inject } from 'aurelia-framework';
import { AppContainer }                  from 'resources/services/app-container';
import { BaseViewModel }                 from 'base-view-model';

@inject(AppContainer)
export class ListClientSubscriptions extends BaseViewModel {

    listingId = 'wemake-client-subscriptions-datatable-listing';

    @bindable({defaultBindingMode: bindingMode.twoWay}) readonly = false;
    @bindable({defaultBindingMode: bindingMode.twoWay}) subscriptions;

    /**
     * Handles bind event
     */
    bind() {
        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:          {
                search:          this.search.bind(this),
                destroySelected: !this.readonly ? this.destroySelected.bind(this) : false,
            },
            edit:                null,
            destroy:             null,
            actions:             [
                {
                    icon:    'icon-trash',
                    action:  (row, $index) => this.destroy($index),
                    tooltip: 'form.button.delete',
                    visible: !this.readonly,
                },
            ],
            buttons:             [],
            selectable:          !this.readonly,
            showResultsPerPage:  false,
            showOptionsDropdown: true,
            showPagination:      false,
            showInfo:            false,
            sorting:             {
                column:    0,
                direction: 'asc',
            },
            columns:             [
                {
                    data:      'diploma_classification',
                    name:      'diploma_classification',
                    title:     'form.field.classification',
                    orderable: false,
                },
                {
                    data:      'source',
                    name:      'source',
                    title:     'form.field.source',
                    orderable: false,
                },
                {
                    data:      'service_type',
                    name:      'service_type',
                    title:     'form.field.service',
                    orderable: false,
                },
                {
                    data:      'consultant',
                    name:      'consultant',
                    title:     'form.field.consultant',
                    orderable: false,
                },
            ],
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        let subscriptions = this.subscriptions;

        if (criteria.search.value !== null && criteria.search.value !== '') {
            let regExp = new RegExp(criteria.search.value, 'gi');

            subscriptions = this.subscriptions.filter((record) => {
                return (record.diploma_classification + '').match(regExp) ||
                    (record.source + '').match(regExp) ||
                    (record.service_type + '').match(regExp) ||
                    (record.consultant + '').match(regExp);
            });
        }

        return Promise.resolve({
            data:            subscriptions,
            recordsTotal:    this.subscriptions.length,
            recordsFiltered: subscriptions.length,
        });
    }

    /**
     * Destroys record
     *
     * @param index
     */
    destroy(index) {
        this.subscriptions.splice(index, 1);
    }

    /**
     * Destroys selected records
     *
     * @param selectedIds
     */
    destroySelected(selectedIds) {
        let unselectedRows = this.datatable.instance.data.filter((row) => !selectedIds.includes(row.id));

        this.datatable.instance.data.splice(0, this.datatable.instance.data.length, ...unselectedRows);

        return Promise.resolve({
            message: this.appContainer.i18n.tr('form.message.success.action-succeeded'),
            status:  true,
        });
    }

}
