import { inject }             from 'aurelia-framework';
import { AppContainer }       from 'resources/services/app-container';
import { MessagesRepository } from 'modules/personal-area/messages/services/repository';
import { BaseViewModel }      from 'base-view-model';

@inject(AppContainer, MessagesRepository)
export class MessageDetails extends BaseViewModel {

    formId = 'personal-area.messages.details-form';

    newRecordRoute = 'personal-area.messages.create';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        super(appContainer);

        this.repository = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can(['personal-area.messages.manage', 'personal-area.messages.view']);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        return this.fetchData(params);
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();

        this.subscribeEventListeners();
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData(params) {
        return Promise.all([
            this.repository.find(params.id).then((response) => {
                this.model = response;
            }),
        ]);
    }

    /**
     * Subscribes event listeners
     */
    subscribeEventListeners() {
        // subscribes `update-message-details` event
        this.eventListeners.push(
            this.appContainer.eventAggregator.subscribe('update-message-details', () => this.fetchData(this.model)),
        );
    }

    /**
     * Returns to messages listing
     */
    back() {
        this.redirectToRoute('personal-area.messages.index');
    }

    /**
     * Navigates to the relatable route
     */
    viewInContext() {
        if (this.model.route && this.model.relatable) {
            this.appContainer.router.navigateToRoute(this.model.route, { id: this.model.relatable.id });
        }
    }

}
