import { BaseModel } from 'modules/core/models/base-model';

export class Consultant extends BaseModel {

    user_id      = null;
    observations = null;
    status_id    = null;

    viewing_diploma_classifications                = [];
    applicability_analysis_diploma_classifications = [];

}
