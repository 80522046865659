import { BaseModel } from 'modules/core/models/base-model';

export class MailSetting extends BaseModel {

    driver       = null;
    host         = null;
    port         = null;
    from_address = null;
    from_name    = null;
    encryption   = null;
    username     = null;
    password     = null;

}
