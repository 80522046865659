import { inject }                           from 'aurelia-framework';
import { BaseFilterFormSchema }             from 'resources/classes/base-filter-form-schema';
import { LocalStorage }                     from 'resources/services/local-storage';
import { EventAggregator }                  from 'aurelia-event-aggregator';
import { I18N }                             from 'aurelia-i18n';
import { ClientTypesRepository }            from 'modules/wemake/client-types/services/repository';
import { ClientStatusesRepository }         from 'modules/wemake/client-statuses/services/repository';
import { CountriesRepository }              from 'modules/administration/countries/services/repository';
import { DiplomaClassificationsRepository } from 'modules/legislation/management/diploma-classifications/services/repository';
import { SourcesRepository }                from 'modules/legislation/management/sources/services/repository';
import { ServiceTypesRepository }           from 'modules/wemake/service-types/services/repository';
import { ConsultantsRepository }            from 'modules/wemake/consultants/services/repository';

@inject(
    LocalStorage,
    EventAggregator,
    I18N,
    ClientTypesRepository,
    ClientStatusesRepository,
    CountriesRepository,
    DiplomaClassificationsRepository,
    SourcesRepository,
    ServiceTypesRepository,
    ConsultantsRepository,
)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     * @param clientTypesRepository
     * @param clientStatusesRepository
     * @param countriesRepository
     * @param diplomaClassificationsRepository
     * @param sourcesRepository
     * @param serviceTypesRepository
     * @param consultantsRepository
     */
    constructor(
        storage,
        eventAggregator,
        i18n,
        clientTypesRepository,
        clientStatusesRepository,
        countriesRepository,
        diplomaClassificationsRepository,
        sourcesRepository,
        serviceTypesRepository,
        consultantsRepository,
    ) {
        super(storage, eventAggregator, i18n);

        this.clientTypesRepository            = clientTypesRepository;
        this.clientStatusesRepository         = clientStatusesRepository;
        this.countriesRepository              = countriesRepository;
        this.diplomaClassificationsRepository = diplomaClassificationsRepository;
        this.sourcesRepository                = sourcesRepository;
        this.serviceTypesRepository           = serviceTypesRepository;
        this.consultantsRepository            = consultantsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Client}
     */
    model(viewModel) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns client data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.number = {
            type:  'text',
            key:   'number',
            label: 'form.field.number',
            size:  2,
        };

        this.name = {
            type:  'text',
            key:   'name',
            label: 'form.field.designation',
            size:  6,
        };

        this.type_id = {
            type:         'select2',
            key:          'type_id',
            label:        'form.field.type',
            size:         4,
            options:      [],
            remoteSource: this.clientTypesRepository.all.bind(this.clientTypesRepository),
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         4,
            options:      [],
            remoteSource: this.clientStatusesRepository.all.bind(this.clientStatusesRepository),
        };

        this.country_id = {
            type:         'select2',
            key:          'country_id',
            label:        'form.field.country',
            size:         4,
            options:      [],
            remoteSource: this.countriesRepository.all.bind(this.countriesRepository),
        };

        this.diploma_classification_id = {
            type:         'select2',
            key:          'diploma_classification_id',
            label:        'form.field.classification',
            size:         4,
            options:      [],
            remoteSource: this.diplomaClassificationsRepository.all.bind(this.diplomaClassificationsRepository),
        };

        this.source_id = {
            type:         'select2',
            key:          'source_id',
            label:        'form.field.source',
            size:         4,
            options:      [],
            remoteSource: this.sourcesRepository.all.bind(this.sourcesRepository),
        };

        this.service_type_id = {
            type:         'select2',
            key:          'service_type_id',
            label:        'form.field.service',
            size:         4,
            options:      [],
            remoteSource: this.serviceTypesRepository.all.bind(this.serviceTypesRepository),
        };

        this.consultant_id = {
            type:         'select2',
            key:          'consultant_id',
            label:        'form.field.consultant',
            size:         4,
            options:      [],
            remoteSource: this.consultantsRepository.all.bind(this.consultantsRepository),
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                listingId: viewModel.listingId,
                criteria:  viewModel.filterModel,
            }),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', viewModel.listingId),
            attributes: {
                class: 'btn btn-light filter-reset',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.number, this.name, this.type_id],
            [this.status_id, this.country_id, this.diploma_classification_id],
            [this.source_id, this.service_type_id, this.consultant_id],
            [this.buttons],
        ];
    }
}
