import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class NotificationsRepository {

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('personal-area/notifications/search', criteria);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get('personal-area/notifications/' + id);
    }

    /**
     * Marks a notification as read
     *
     * @param id
     *
     * @returns {*}
     */
    read(id) {
        return this.httpClient.get('personal-area/notifications/' + id + '/read');
    }

    /**
     * Marks the given notifications as read
     *
     * @param ids
     *
     * @returns {*}
     */
    readSelected(ids) {
        return this.httpClient.post('personal-area/notifications/read-selected', { ids: ids });
    }

    /**
     * Marks a notification as dismissed
     *
     * @param id
     *
     * @returns {*}
     */
    dismiss(id) {
        return this.httpClient.get('personal-area/notifications/' + id + '/dismiss');
    }

}
