import { inject }           from 'aurelia-framework';
import { MailSetting }      from 'modules/wemake/models/mail-setting';
import { TestMailSettings } from 'modules/wemake/mail-settings/test/index';
import { DialogService }    from 'aurelia-dialog';

@inject(DialogService)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param dialogService
     */
    constructor(dialogService) {
        this.dialogService = dialogService;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {MailSetting}
     */
    model() {
        let model = new MailSetting();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.driver = {
            type:         'select2',
            key:          'driver',
            label:        'form.field.driver',
            size:         3,
            remoteSource: viewModel.repository.drivers.bind(viewModel.repository),
        };

        this.host = {
            type:  'text',
            key:   'host',
            label: 'form.field.host',
            size:  3,
        };

        this.port = {
            type:  'text',
            key:   'port',
            label: 'form.field.port',
            size:  3,
        };

        this.encryption = {
            type:         'select2',
            key:          'encryption',
            label:        'form.field.encryption',
            size:         3,
            remoteSource: viewModel.repository.encryptionTypes.bind(viewModel.repository),
            required:     false,
        };

        this.from_address = {
            type:  'text',
            key:   'from_address',
            label: 'form.field.from-address',
            size:  3,
        };

        this.from_name = {
            type:  'text',
            key:   'from_name',
            label: 'form.field.from-name',
            size:  3,
        };

        this.username = {
            type:     'text',
            key:      'username',
            label:    'form.field.username',
            size:     3,
            required: false,
        };

        this.password = {
            type:     'password',
            key:      'password',
            label:    'form.field.password',
            size:     3,
            required: false,
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.testButton = {
            type:       'button',
            label:      'form.button.to-test',
            action:     () => this.dialogService.open({viewModel: TestMailSettings}),
            attributes: {
                class: 'btn btn-success',
            },
            icon:       {
                attributes: {
                    class: 'icon-mail5',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.submitButton,
                this.testButton,
            ],
        };

        return [
            [this.driver, this.host, this.port, this.encryption],
            [this.from_address, this.from_name],
            [this.username, this.password],
            [this.buttons],
        ];
    }

}
