import { bindable, bindingMode, inject } from 'aurelia-framework';
import { AppContainer }                  from 'resources/services/app-container';
import { ClientSubscriptionsRepository } from 'modules/wemake/clients/subscriptions/services/repository';
import { FormSchema }                    from 'modules/wemake/clients/subscriptions/create/form-schema';

@inject(AppContainer, ClientSubscriptionsRepository, FormSchema)
export class CreateClientSubscriptionCustomElement {

    formId = 'wemake-client-subscriptions-creation-form';

    @bindable({defaultBindingMode: bindingMode.twoWay}) subscriptions;

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {

        this.appContainer = appContainer;
        this.repository   = repository;
        this.formSchema   = formSchema;

    }

    /**
     * Handles created event
     */
    created() {
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        this.schema = this.formSchema.schema(this);
    }

    /**
     * Submits view form
     */
    submit() {
        let selectedClassifications = this.formSchema.diploma_classifications.instance.selectedItems();
        let selectedSources         = this.formSchema.sources.instance.selectedItems();

        for (let i = 0; i < this.model.diploma_classifications.length; i++) {
            for (let j = 0; j < this.model.sources.length; j++) {
                let tempModel = this.formSchema.model();

                tempModel.assign(this.model);

                tempModel.id                        = generateUuid();
                tempModel.country                   = this.dropdownSelectedText('client_service_country_id');
                tempModel.service_type              = this.dropdownSelectedText('client_service_service_type_id');
                tempModel.consultant                = this.dropdownSelectedText('client_service_consultant_id');
                tempModel.diploma_classification    = selectedClassifications.find(item => item.id === this.model.diploma_classifications[i]).name;
                tempModel.source                    = selectedSources.find(item => item.id === this.model.sources[j]).name;
                tempModel.diploma_classification_id = this.model.diploma_classifications[i];
                tempModel.source_id                 = this.model.sources[j];

                this.subscriptions.push(tempModel);
            }
        }

        this.resetForm();
    }

    /**
     * Returns dropdown selected text
     *
     * @param field
     *
     * @returns {*|jQuery}
     */
    dropdownSelectedText(field) {
        return $('#' + field + ' option:selected').text();
    }

    /**
     * Returns dropdown selected text
     *
     * @param field
     *
     * @param value
     * @returns {*|jQuery}
     */
    dropdownMultiSelectedText(field, value) {
        let text = '';

        $('#' + field + ' option:selected').each((index, element) => {
            if (element.model === value) {
                text = element.value;
            }
        });

        return text;
    }

    /**
     * Resets form fields
     */
    resetForm(nullifyAlert = true) {
        this.resetModelValues()
            .then(() => {
                // publishes `form-reseted` event
                this.appContainer.eventAggregator.publish('form-reseted', this.formId);

                if (nullifyAlert) {
                    this.alert = null;
                }
            });
    }

    /**
     * Resets model to initial values
     *
     * @returns {Promise}
     */
    resetModelValues() {
        return new Promise((resolve, reject) => {
                this.model.assign(this.initialModel);
                resolve(true);
                reject(new Error('Error'));
            },
        );
    }
}
