import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class ClientsRepository {

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Returns all records
     *
     * @returns {*}
     */
    all() {
        return this.httpClient.get('wemake/clients/all');
    }

    /**
     * Returns active records
     *
     * @returns {*}
     */
    active() {
        return this.httpClient.get('wemake/clients/active');
    }

    /**
     * Fetches client's company organization tree
     *
     * @param id
     *
     * @returns {*}
     */
    companyOrganizationTree(id) {
        return this.httpClient.get(`wemake/clients/${id}/company-organization/tree`);
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('wemake/clients/search', criteria);
    }

    /**
     * Creates a record
     *
     * @param data
     *
     * @returns {*}
     */
    create(data) {
        return this.httpClient.post('wemake/clients', data);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get(`wemake/clients/${id}`);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put(`wemake/clients/${id}`, data);
    }

    /**
     * Destroys a record
     *
     * @param id
     *
     * @returns {*}
     */
    destroy(id) {
        return this.httpClient.delete(`wemake/clients/${id}`);
    }

    /**
     * Destroys selected records
     *
     * @param ids
     *
     * @returns {*}
     */
    destroySelected(ids) {
        return this.httpClient.delete('wemake/clients/destroy-selected', {ids: ids});
    }

    /**
     * Send daily report for a given client
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    sendDailyReport(id, data) {
        return this.httpClient.post(`wemake/clients/${id}/reports/daily/send`, data);
    }

    /**
     * Send monthly report for a given client
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    sendMonthlyReport(id, data) {
        return this.httpClient.post(`wemake/clients/${id}/reports/monthly/send`, data);
    }

}
