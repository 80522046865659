import { bindable, inject }       from 'aurelia-framework';
import { AppContainer }           from 'resources/services/app-container';
import { BaseFormViewModel }      from 'base-form-view-model';
import { MailSettingsRepository } from 'modules/wemake/mail-settings/services/repository';
import { FormSchema }             from 'modules/wemake/mail-settings/test/form-schema';
import { DialogController }       from 'aurelia-dialog';

@inject(AppContainer, MailSettingsRepository, FormSchema, DialogController)
export class TestMailSettings extends BaseFormViewModel {

    formId      = 'wemake.mail-settings.test-form';
    discardable = true;

    @bindable alert;
    @bindable settings = {
        title: 'form.title.test-mail-settings',
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     * @param dialogController
     */
    constructor(appContainer, repository, formSchema, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.formSchema       = formSchema;
        this.dialogController = dialogController;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'wemake.mail-settings.manage',
            'wemake.mail-settings.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        this.schema       = this.formSchema.schema(this);
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(null);
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        return this.repository.test(this.model)
            .then((response) => {
                if (response.status === true) {
                    this.appContainer.notifier.successNotice(response.message);
                } else {
                    this.alert = this.alertMessage(response.status, response.message, response.errors);
                }

                return response.status;
            })
            .catch(console.log.bind(console));
    }

}
