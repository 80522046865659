import { bindable, containerless, customElement, inject } from 'aurelia-framework';
import { AppContainer }                                   from 'resources/services/app-container';
import { MessagesRepository }                             from 'modules/personal-area/messages/services/repository';

@containerless()
@customElement('message-item')
@inject(AppContainer, MessagesRepository)
export class MessageItem {

    @bindable model;
    @bindable replyModel = {body: null};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        this.appContainer = appContainer;
        this.repository   = repository;
    }

    /**
     * Submits view form
     */
    submit() {
        // calls repository create method
        this.repository.reply(this.model.id, this.replyModel)
            .then(response => {
                if (response.status === true) {
                    this.appContainer.eventAggregator.publish('update-message-details');
                    this.toggleReplyElement();
                }
            }).catch(console.log.bind(console));
    }

    /**
     * Checks if the message allows reply
     *
     * @returns {boolean}
     */
    allowsReply() {
        let isOwner = this.model.addresser.id === this.appContainer.authenticatedUser.user.id;

        return !isOwner && this.model.allows_reply;
    }

    /**
     * Handles reply button
     */
    reply() {
        this.toggleReplyElement();
    }

    /**
     * Toggles reply element
     */
    toggleReplyElement() {
        this.replyModel = {body: null};

        $('#reply-message-' + this.model.id).toggleClass('d-none');
    }
}
