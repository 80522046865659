import { inject }                  from 'aurelia-framework';
import { BaseViewModel }           from 'base-view-model';
import { NotificationsRepository } from 'modules/personal-area/notifications/services/repository';
import { AppContainer }            from 'resources/services/app-container';

@inject(AppContainer, NotificationsRepository)
export class NotificationDetails extends BaseViewModel {

    formId = 'personal-area.notifications.details-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        super(appContainer);

        this.repository = repository;
    }

    get viewInContextRoute() {
        if (this.model.route && this.model.relatable) {
            return this.appContainer.router.generate(this.model.route, {id: this.model.relatable.id});
        }

        return null;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can(['personal-area.notifications.manage', 'personal-area.notifications.view']);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        return this.fetchData(params);
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData(params) {
        return this.repository.find(params.id).then((response) => this.model = response);
    }

    /**
     * Returns to messages listing
     */
    back() {
        this.redirectToRoute('personal-area.notifications.index');
    }

    /**
     * View notification in its context
     */
    viewInContext() {
        if (this.model.route && this.model.relatable) {
            this.redirectToRoute({
                name:   this.model.route,
                params: {id: this.model.relatable.id},
            });
        }
    }
}
