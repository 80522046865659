import { inject, PLATFORM }      from 'aurelia-framework';
import { AppContainer }          from 'resources/services/app-container';
import { BaseFormViewModel }     from 'base-form-view-model';
import { ConsultantsRepository } from 'modules/wemake/consultants/services/repository';
import { FormSchema }            from 'modules/wemake/consultants/form-schema';

@inject(AppContainer, ConsultantsRepository, FormSchema)
export class EditConsultant extends BaseFormViewModel {

    headerTitle    = 'form.title.edit-record';
    newRecordRoute = 'wemake.consultants.create';
    formId         = 'wemake-consultants-edition-form';

    alert = {};
    model = {};

    consultantDataSchema        = {};
    viewingSchema               = {};
    applicabilityAnalysisSchema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/wemake/consultants/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'wemake.consultants.manage',
            'wemake.consultants.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.repository.find(params.id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);

            this.consultantDataSchema        = this.formSchema.consultantDataSchema(this);
            this.viewingSchema               = this.formSchema.viewingSchema(this);
            this.applicabilityAnalysisSchema = this.formSchema.applicabilityAnalysisSchema(this);
            this.globalButtonsSchema         = this.formSchema.globalButtonsSchema(this);
        });
    }

}
