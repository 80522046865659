import { BaseModel } from 'modules/core/models/base-model';
import moment        from 'moment';

export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        month: null,
        year:  moment().year(),
    };

    /**
     * Returns a new instance of the model
     *
     * @returns {{}}
     */
    model() {
        let model = new BaseModel();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.month = {
            type:  'months-select',
            key:   'month',
            label: 'form.field.month',
            size:  6,
        };

        this.year = {
            type:       'number',
            key:        'year',
            label:      'form.field.year',
            size:       6,
            attributes: {
                min: 0,
            },
        };

        return [
            [this.month, this.year],
        ];
    }

}
