import { inject, PLATFORM }          from 'aurelia-framework';
import { AppContainer }              from 'resources/services/app-container';
import { BaseListViewModel }         from 'base-list-view-model';
import { NotificationsRepository }   from 'modules/personal-area/notifications/services/repository';
import { FilterFormSchema }          from 'modules/personal-area/notifications/filter-form-schema';
import { DismissNotificationDialog } from 'modules/personal-area/notifications/dialogs/dismiss';
import { DialogService }             from 'aurelia-dialog';
import { InfoDialog }                from '../../../resources/elements/html-elements/dialogs/info-dialog';

@inject(AppContainer, NotificationsRepository, FilterFormSchema, DialogService)
export class ListNotifications extends BaseListViewModel {

    listingId   = 'personal-area-notifications-listing';
    headerTitle = 'listing.personal-area.notifications';

    repository;
    datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filterFormSchema
     * @param dialogService
     */
    constructor(appContainer, repository, filterFormSchema, dialogService) {
        super(appContainer);

        this.repository       = repository;
        this.filterFormSchema = filterFormSchema;
        this.dialogService    = dialogService;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/list.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'personal-area.notifications.manage',
            'personal-area.notifications.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.personal-area.notification',
            repository:      this.repository,
            edit:            null,
            destroy:         null,
            destroySelected: false,
            actions:         [
                {
                    icon:    'icon-file-eye',
                    action:  (row) => this.viewNotification(row),
                    tooltip: 'form.button.view',
                },
                {
                    icon:    'icon-file-text',
                    action:  (row) => this.viewNotificationInContext(row),
                    tooltip: 'form.button.view-in-context',
                    visible: (row) => row.dismissed_at === null,
                },
                {
                    icon:    'icon-check',
                    action:  (row) => this.dismissTask(row),
                    tooltip: 'form.button.dismiss',
                    visible: (row) => row.dismissed_at === null,
                },
            ],
            options:         [
                {
                    icon:    'icon-checkmark3',
                    tooltip: 'form.button.mark-selected-as-read',
                    label:   'form.button.mark-selected-as-read',
                    action:  () => this.markSelectedAsRead(),
                },
            ],
            buttons:         [],
            selectable:      true,
            sorting:         {
                column:    2,
                direction: 'desc',
            },
            columns:         [
                {
                    data:  'task_type',
                    name:  'notification_types.name',
                    title: 'form.field.type',
                },
                {
                    data:  'subject',
                    name:  'notifications.subject',
                    title: 'form.field.subject',
                },
                {
                    data:  'created_at',
                    name:  'notifications.created_at',
                    title: 'form.field.created-at',
                },
                {
                    data:  'deadline',
                    name:  'notifications.deadline',
                    title: 'form.field.deadline',
                },
                {
                    data:  'status',
                    name:  'notification_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }

    /**
     * Handles view action button
     *
     * @param row
     */
    viewNotification(row) {
        // marks the notification has read
        this.repository.read(row.id).then(() => {
            let route = this.appContainer.router.generate('personal-area.notifications.details', {id: row.id});

            this.appContainer.router.navigate(route);
        });
    }

    /**
     * Handles view action button
     *
     * @param row
     */
    viewNotificationInContext(row) {
        if (row.relatable && row.route) {
            let route = this.appContainer.router.generate(row.route, {id: row.relatable.id});

            // marks the notification has read
            this.repository.read(row.id).then(() => this.appContainer.router.navigate(route));
        }
    }

    /**
     * Handles dismiss action button
     *
     * @param row
     */
    dismissTask(row) {
        this.dialogService
            .open({viewModel: DismissNotificationDialog})
            .whenClosed((dialogResponse) => {
                if (!dialogResponse.wasCancelled) {
                    this.repository
                        .dismiss(row.id)
                        .then((response) => {
                            this.appContainer.notifier.responseNotice(response);

                            if (response.status === true) {
                                this.datatable.instance.reload();
                            }
                        });
                }
            });
    }

    /**
     * Marks the selected records as read
     */
    markSelectedAsRead() {
        let selectedRows = this.datatable.instance.selectedRows;

        if (!selectedRows.length) {
            return this.dialogService.open({
                viewModel: InfoDialog,
                model:     {
                    body:  this.appContainer.i18n.tr('message.select-at-least-one-record'),
                    title: this.appContainer.i18n.tr('text.attention'),
                },
            });
        }

        this.repository
            .readSelected(selectedRows)
            .then((response) => {
                this.appContainer.notifier.responseNotice(response);

                if (response.status === true) {
                    this.datatable.instance.reload();
                }
            });
    }

}
