import { bindable, inject, PLATFORM } from 'aurelia-framework';
import { AppContainer }               from 'resources/services/app-container';
import { BaseViewModel }              from 'base-view-model';
import { ClientsRepository }          from 'modules/wemake/clients/services/repository';
import { FilterFormSchema }           from 'modules/wemake/clients/filter-form-schema';
import { ClientDailyReport }          from 'modules/wemake/clients/reports/daily/index';
import { ClientMonthlyReport }        from 'modules/wemake/clients/reports/monthly/index';
import { DialogService }              from 'aurelia-dialog';

@inject(AppContainer, ClientsRepository, FilterFormSchema, DialogService)
export class ListClients extends BaseViewModel {

    listingId = 'wemake-clients-listing';

    @bindable headerTitle    = 'listing.wemake.clients';
    @bindable newRecordRoute = 'wemake.clients.create';

    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filterFormSchema
     * @param dialogService
     */
    constructor(appContainer, repository, filterFormSchema, dialogService) {
        super(appContainer);

        this.repository       = repository;
        this.filterFormSchema = filterFormSchema;
        this.dialogService    = dialogService;

        this.newRecordRoute = appContainer.authenticatedUser.can(['wemake.clients.manage', 'wemake.clients.create']) ? this.newRecordRoute : null;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/list.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'wemake.clients.manage',
            'wemake.clients.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:           'resource.wemake.client',
            repository:         this.repository,
            edit:               'wemake.clients.edit',
            destroy:            'wemake.clients.destroy',
            show:               'wemake.clients.view',
            actionsContextMenu: true,
            actions:            [
                {
                    tooltip: 'form.button.send-daily-report',
                    icon:    'icon-calendar',
                    action:  (client) => this.dialogService.open({
                        viewModel: ClientDailyReport,
                        model:     client,
                    }),
                },
                {
                    tooltip: 'form.button.send-monthly-report',
                    icon:    'icon-calendar52',
                    action:  (client) => this.dialogService.open({
                        viewModel: ClientMonthlyReport,
                        model:     client,
                    }),
                },
            ],
            options:            [],
            buttons:            [],
            selectable:         true,
            sorting:            {
                column:    0,
                direction: 'asc',
            },
            columns:            [
                {
                    data:  'number',
                    name:  'clients.number',
                    title: 'form.field.number',
                },
                {
                    data:  'name',
                    name:  'clients.name',
                    title: 'form.field.designation',
                },
                {
                    data:  'subdomain',
                    name:  'clients.subdomain',
                    title: 'form.field.subdomain',
                },
                {
                    data:  'type',
                    name:  'client_types.name',
                    title: 'form.field.type',
                },
                {
                    data:  'status',
                    name:  'client_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }

}
