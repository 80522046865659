import { inject }                           from 'aurelia-framework';
import { Consultant }                       from 'modules/wemake/models/consultant';
import { BooleanStatus }                    from 'modules/administration/models/boolean-status';
import { RolesRepository }                  from 'modules/administration/roles/services/repository';
import { BooleanStatusesRepository }        from 'modules/administration/boolean-statuses/services/repository';
import { DiplomaClassificationsRepository } from 'modules/legislation/management/diploma-classifications/services/repository';

@inject(RolesRepository, BooleanStatusesRepository, DiplomaClassificationsRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param rolesRepository
     * @param booleanStatusesRepository
     * @param diplomaClassificationsRepository
     */
    constructor(rolesRepository, booleanStatusesRepository, diplomaClassificationsRepository) {
        this.rolesRepository                  = rolesRepository;
        this.booleanStatusesRepository        = booleanStatusesRepository;
        this.diplomaClassificationsRepository = diplomaClassificationsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Consultant}
     */
    model() {
        let model = new Consultant();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns consultant data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    consultantDataSchema(viewModel) {
        this.user_id = {
            type:         'select2',
            key:          'user_id',
            label:        'form.field.consultant',
            size:         6,
            options:      [],
            remoteSource: this.rolesRepository.consultants.bind(this.rolesRepository),
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        this.observations = {
            type:       'textarea',
            key:        'observations',
            label:      'form.field.observations',
            size:       12,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         6,
            options:      [],
            remoteSource: this.booleanStatusesRepository.active.bind(this.booleanStatusesRepository),
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        return [
            [this.user_id],
            [this.observations],
            [this.status_id],
        ];
    }

    /**
     * Returns viewing form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    viewingSchema(viewModel) {
        this.viewing_diploma_classifications = {
            type:         'fancytree',
            key:          'viewing_diploma_classifications',
            label:        'form.field.classifications',
            size:         12,
            options:      [],
            remoteSource: this.diplomaClassificationsRepository.tree.bind(this.diplomaClassificationsRepository, true),
            settings:     {
                disableSelected: true,
                repository:      this.diplomaClassificationsRepository,
            },
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        return [
            [this.viewing_diploma_classifications],
        ];
    }

    /**
     * Returns applicability analysis form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    applicabilityAnalysisSchema(viewModel) {

        this.applicability_analysis_diploma_classifications = {
            type:         'fancytree',
            key:          'applicability_analysis_diploma_classifications',
            label:        'form.field.classifications',
            size:         12,
            options:      [],
            remoteSource: this.diplomaClassificationsRepository.tree.bind(this.diplomaClassificationsRepository, true),
            settings:     {
                disableSelected: true,
                repository:      this.diplomaClassificationsRepository,
            },
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        return [
            [this.applicability_analysis_diploma_classifications],
        ];
    }

    /**
     * Returns global buttons schema
     *
     * @returns {*[]}
     */
    globalButtonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('wemake.consultants.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }
}
