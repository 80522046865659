import { inject }                 from 'aurelia-framework';
import { AppContainer }           from 'resources/services/app-container';
import { BaseFormViewModel }      from 'base-form-view-model';
import { MailSettingsRepository } from 'modules/wemake/mail-settings/services/repository';
import { FormSchema }             from 'modules/wemake/mail-settings/edit/form-schema';

@inject(AppContainer, MailSettingsRepository, FormSchema)
export class EditMailSettings extends BaseFormViewModel {

    headerTitle = 'page.sidebar.mail-settings';
    formId      = 'wemake.mail-settings.edition-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'wemake.mail-settings.manage',
            'wemake.mail-settings.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.repository.settings().then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);

            this.schema = this.formSchema.schema(this);
        });
    }

    /**
     * Submits view form
     */
    submit() {
        this.repository.update(this.model)
            .then(response => {
                this.alert = this.alertMessage(response.status, response.message, response.errors);

                if (response.status === true) {
                    this.initialModel.assign(this.model);
                }
            })
            .catch(console.log.bind(console));
    }

}
