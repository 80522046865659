import { inject }                           from 'aurelia-framework';
import { CustomHttpClient }                 from 'resources/services/custom-http-client';
import { ConsultantsRepository }            from 'modules/wemake/consultants/services/repository';
import { CountriesRepository }              from 'modules/administration/countries/services/repository';
import { DiplomaClassificationsRepository } from 'modules/legislation/management/diploma-classifications/services/repository';
import { ServiceTypesRepository }           from 'modules/wemake/service-types/services/repository';
import { SourcesRepository }                from 'modules/legislation/management/sources/services/repository';

@inject(CustomHttpClient, ConsultantsRepository, CountriesRepository, DiplomaClassificationsRepository, ServiceTypesRepository, SourcesRepository)
export class ClientSubscriptionsRepository {

    /**
     * Constructor
     *
     * @param httpClient
     * @param consultantsRepository
     * @param countriesRepository
     * @param diplomaClassificationsRepository
     * @param sourcesRepository
     * @param serviceTypesRepository
     */
    constructor(httpClient, consultantsRepository, countriesRepository, diplomaClassificationsRepository, serviceTypesRepository, sourcesRepository) {
        this.httpClient = httpClient;

        this.consultantsRepository            = consultantsRepository;
        this.countriesRepository              = countriesRepository;
        this.diplomaClassificationsRepository = diplomaClassificationsRepository;
        this.serviceTypesRepository           = serviceTypesRepository;
        this.sourcesRepository                = sourcesRepository;
    }

    /**
     * Fetches active consultants from server
     *
     * @returns {*}
     */
    activeConsultants() {
        return this.consultantsRepository.active();
    }

    /**
     * Fetches active countries from server
     *
     * @returns {*}
     */
    activeCountries() {
        return this.countriesRepository.active();
    }

    /**
     * Fetches active diploma classifications from server
     *
     * @returns {*}
     */
    activeDiplomaClassifications() {
        return this.diplomaClassificationsRepository.active();
    }

    /**
     * Fetches active service types from server
     *
     * @returns {*}
     */
    activeServiceTypes() {
        return this.serviceTypesRepository.active();
    }

    /**
     * Fetches active sources from server
     *
     * @returns {*}
     */
    activeSources() {
        return this.sourcesRepository.active();
    }
}
