import { BaseModel } from 'modules/core/models/base-model';

export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Returns a new instance of the model
     *
     * @returns {{}}
     */
    model() {
        let model = new BaseModel();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.date = {
            type:  'material-ui-date-picker',
            key:   'date',
            label: 'form.field.date',
            size:  12,
        };

        return [
            [this.date],
        ];
    }

}
