import { inject, PLATFORM }  from 'aurelia-framework';
import { AppContainer }      from 'resources/services/app-container';
import { BaseFormViewModel } from 'base-form-view-model';
import { ClientsRepository } from 'modules/wemake/clients/services/repository';
import { FormSchema }        from 'modules/wemake/clients/form-schema';

@inject(AppContainer, ClientsRepository, FormSchema)
export class EditClient extends BaseFormViewModel {

    headerTitle    = 'form.title.edit-record';
    newRecordRoute = 'wemake.clients.create';
    formId         = 'wemake-clients-edition-form';

    alert = {};
    model = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/wemake/clients/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'wemake.clients.manage',
            'wemake.clients.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.repository.find(params.id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);

            this.clientDataSchema    = this.formSchema.clientDataSchema(this);
            this.globalButtonsSchema = this.formSchema.globalButtonsSchema(this);
        });
    }

}
