import { BaseModel } from 'modules/core/models/base-model';

export class ClientSubscription extends BaseModel {

    country_id              = null;
    source_id               = null;
    service_type_id         = null;
    consultant_id           = null;
    diploma_classifications = [];
    sources                 = [];

}
