import { BaseModel } from 'modules/core/models/base-model';

const LEVEL_1 = 1;
const LEVEL_2 = 2;
const LEVEL_3 = 3;

export class ServiceType extends BaseModel {

    acronym     = null;
    name        = null;
    description = null;
    status_id   = null;

    static get LEVEL_1() {
        return LEVEL_1;
    }

    static get LEVEL_2() {
        return LEVEL_2;
    }

    static get LEVEL_3() {
        return LEVEL_3;
    }

}
